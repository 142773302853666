<template>
  <span
    ref="target"
    :class="
      $route.path != '/control_panel'
        ? ''
        : $route.path != '/orders'
        ? ''
        : $route.path != '/add_service'
        ? ''
        : $route.path != '/services'
        ? ''
        : $route.path != '/edit_service'
        ? ''
        : 'p-5'
    "
  ></span>
  <navbar-component
    style="z-index: 100"
    v-if="
      $route.path != '/control_panel' &&
      $route.path != '/orders' &&
      $route.path != '/add_service' &&
      $route.path != '/services' &&
      $route.path != '/edit_service'
    "
  ></navbar-component>
  <router-view />
  <hr />
  <div class="footer" id="footer">
    <div class="d-flex justify-content-center heading-footer">تابعنا</div>
    <div class="container heading2-footer mt-5">
      <div
        class="row d-flex justify-content-center"
        style="height: fit-content"
      >
        <div class="col-md-4 col-12">
          <Icon icon="ant-design:instagram-outlined" />Instagram
        </div>
        <div class="col-md-4 col-12"><Icon icon="mdi:twitter" />@Twitter</div>
      </div>
      <div
        class="d-flex justify-content-center arrow"
        style="width: 100%; height: fit-content"
      >
        <div class="d-flex justify-content-center">
          <a href="#start"
            ><Icon
              icon="streamline:interface-arrows-turn-up-arrow-bend-curve-change-direction-return-up"
              width="50"
              height="50"
              style="color: rgb(40, 40, 40)"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import axios from "axios";
import store from "./store";
export default {
  name: "my-app",
  components: {
    NavbarComponent,
    Icon,
  },
  methods: {
    scrollToElement() {
      this.$refs.target.scrollIntoView({
        behavior: "smooth",
      });
    },
    clearLocalStorage() {
      window.onbeforeunload = () => {
        window.localStorage.removeItem("token");
      };
    },
    getuserorders() {
      if (window.localStorage.getItem("token") !== null) {
        const token = window.localStorage.getItem("token");
        axios({
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
          url: "https://1-tech-masters.com/supportOffice/public/api/cp/orders?all=1",
        })
          .then((response) => {
            this.changedata(0, response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    changedata(state, Basket) {
      if (state == 0) {
        store.state.elements_in_basket = 0;
      }
      let display = {};
      for (let i = 0; i < Object.keys(Basket).length; i++) {
        if (Basket[i].status == state) {
          display[i] = Basket[i];
          if (state == 0) {
            store.state.elements_in_basket++;
          }
        }
      }
    },
  },
  watch: {
    $route(to, from) {
      this.scrollToElement();
      if ("service_id" in to.query) {
        store.state.service_id = to.query.service_id;
        console.log(store.state.service_id);
      }
      var x = to + from;
      var y = x;
      x = y;
      if (window.localStorage.getItem("token") === null) {
        store.state.login = "تسجيل الدخول";
        store.state.save_url = "/login";
      } else {
        store.state.login = "تسجيل الخروج";
        store.state.save_url = "/";
      }
      if (from.path == "/login") {
        this.getuserorders();
      }
    },
  },
  mounted() {
    this.clearLocalStorage();
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;

  a {
    font-weight: 300;
    text-decoration: none;
    color: rgba($color: #ffffff, $alpha: 0.5);
    transition: 0.5s ease-in-out;

    &.router-link-exact-active {
      text-decoration: underline;
      color: #d9be5e;
    }
  }
  a:hover {
    color: #ffffff;
  }
}
.footer {
  height: fit-content;
}
.heading-footer {
  font-size: 50px;
}
.heading2-footer {
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.logo {
  height: 20px;
}
@media screen and (max-width: 730px) {
  .logo {
    height: 50px;
    margin-left: 0px;
  }
}
</style>
