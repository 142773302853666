<template>
  <div class="buy-page">
    <div class="container" style="margin-top: 100px">
      <div class="card w-100">
        <div class="card-title mt-3">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-4 d-flex justify-content-center align-items-center">
              <h2 class="headers">الخدمة</h2>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
              <h2 class="headers">السعر</h2>
            </div>
            <div class="col-4 d-flex justify-content-center align-items-center">
              <h2 class="headers">الكمية</h2>
            </div>
          </div>
        </div>
        <hr
          style="
            width: 100%;
            height: 2px;
            color: #d9be5e;
            background-color: #d9be5e;
            opacity: 1;
          "
        />
        <div
          class="card-text"
          v-if="Object.keys($store.state.serBasket).length > 1"
        >
          <div
            class="row"
            v-for="(element, index) in $store.state.serBasket"
            :key="index"
            :style="{
              display:
                index == 0
                  ? 'none'
                  : Object.keys(element).length == 0
                  ? 'none'
                  : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }"
          >
            <div
              class="col-4"
              :style="{
                display:
                  index == 0
                    ? 'none'
                    : Object.keys(element).length == 0
                    ? 'none'
                    : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
              v-if="index != 0"
            >
              <h4>
                {{ element.name }}
              </h4>
            </div>
            <div
              class="col-4"
              :style="{
                display:
                  index == 0
                    ? 'none'
                    : Object.keys(element).length == 0
                    ? 'none'
                    : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
              v-if="index != 0"
            >
              <h4>
                {{ element.price }}
              </h4>
            </div>
            <div
              class="col-4"
              :style="{
                display:
                  index == 0
                    ? 'none'
                    : Object.keys(element).length == 0
                    ? 'none'
                    : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
              v-if="index != 0"
            >
              <h4>
                {{ $store.state.basket[index].count }}
              </h4>
            </div>
            <hr
              style="
                width: 95%;
                height: 2px;
                color: #d9be5e;
                background-color: #d9be5e;
                opacity: 1;
              "
            />
          </div>
        </div>
        <div class="card-text">
          <div class="row">
            <div class="col-6"><h3>السعر الكلي</h3></div>
            <div class="col-6">
              <h3>{{ $store.state.amount }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "buy-page",
};
</script>

<style scoped>
.headers {
  text-decoration: underline 2px #d9be5e;
}
</style>
