import { createStore } from "vuex";

export default createStore({
  state: {
    elements_in_basket: 0,
    service_id: 0,
    basket: { 0: "1" },
    serBasket: { 0: "1" },
    amount: 0,
    save_url: "",
    flag: 0,
    login: "",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
