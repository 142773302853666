<template>
  <div class="support-academy">
    <ServicesView
      service_name="أكاديمية دعم"
      :services="supportacademy"
    ></ServicesView>
  </div>
</template>

<script>
import ServicesView from "@/components/ServicesView.vue";
import axios from "axios";
export default {
  name: "support-academy",
  components: {
    ServicesView,
  },
  data() {
    return {
      supportacademy: {},
    };
  },

  methods: {
    getservices() {
      var counter = 1;
      for (var i = 7; i < 9; i++) {
        axios
          .get(
            "https://1-tech-masters.com/supportOffice/public/api/get-services-for-section/" +
              i
          )
          .then((response) => {
            for (
              var j = 0;
              j < Object.keys(response.data.service).length;
              j++
            ) {
              if (response.data.service[j].offer != null) {
                const price1 = response.data.service[j].offer.offer_price;
                const discount1 = response.data.service[j].offer.discount;
                const price_before1 = response.data.service[j].price;
                this.supportacademy[counter] = response.data.service[j];
                Object.assign(
                  this.supportacademy[counter],
                  { discount: discount1 },
                  { price_before: price_before1 },
                  { price: price1 }
                );
              } else {
                this.supportacademy[counter] = response.data.service[j];
              }
              counter++;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getservices();
  },
};
</script>
