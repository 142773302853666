<template>
  <div class="discounts-view">
    <ServicesView service_name="التخفيضات" :services="discounts"></ServicesView>
  </div>
</template>

<script>
import ServicesView from "@/components/ServicesView.vue";
import axios from "axios";
export default {
  name: "discounts-view",
  components: {
    ServicesView,
  },
  data() {
    return {
      discounts: {},
    };
  },
  methods: {
    getdata() {
      axios
        .get("https://1-tech-masters.com/supportOffice/public/api/get-offers")
        .then((response) => {
          console.log(response);
          for (var i = 0; i < Object.keys(response.data).length; i++) {
            const price_before1 = response.data[i].service.price;
            const price1 = response.data[i].offer_price;
            const discount1 = response.data[i].discount;
            this.discounts[i + 1] = response.data[i].service;
            Object.assign(
              this.discounts[i + 1],
              { discount: discount1 },
              { price_before: price_before1 },
              { price: price1 }
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>
