<template>
  <div class="login-view">
    <div class="container-1" id="start">
      <div class="card glass">
        <div class="card-title mt-5">
          <img src="@/assets/Photos/use_6_copy.png" class="logo" alt="" />
        </div>
        <div class="card-title m-3">
          <h1>مركز الدعم للخدمات العامة</h1>
        </div>
        <div class="card-text mt-3">
          <div class="row w-100">
            <div class="col-12">
              <input
                type="text"
                class="form_inp"
                name="phone"
                v-model="phone"
                placeholder="ادخل رقم الهاتف"
              />
            </div>
            <div class="col-12">
              <input
                id="pass"
                type="password"
                name="password"
                class="form_inp"
                v-model="password"
                placeholder="ادخل كلمة المرور"
              />
              <Icon
                :icon="myicon"
                :color="mycolor"
                class="show_hide"
                @click="showpassword()"
              />
            </div>
            <div
              class="col-12 d-flex justify-content-center align-items-center"
            >
              <button class="login" @click="login()">تسجيل الدخول</button>
            </div>
            <div
              class="mt-3 mb-3 col-12 d-flex justify-content-center align-items-center"
            >
              <router-link to="/register">ليس لديك حساب؟</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { Icon } from "@iconify/vue";
import axios from "axios";
export default {
  name: "login-view",
  data() {
    return {
      phone: "",
      password: "",
      myicon: "gridicons:visible",
      mycolor: "#3c3c3c",
    };
  },
  components: {
    Icon,
  },
  methods: {
    showpassword() {
      const ele = document.getElementById("pass");
      if (ele.type == "password") {
        ele.type = "text";
        this.myicon = "gridicons:not-visible";
        this.mycolor = "#ffffff";
      } else {
        ele.type = "password";
        this.myicon = "gridicons:visible";
        this.mycolor = "#3c3c3c";
      }
    },
    login() {
      if (this.phone == "") {
        window.alert("رقم الهاتف فارغ");
        return;
      } else if (this.password == "") {
        window.alert("كلمة المرور فارغة");
        return;
      } else {
        axios({
          method: "post",
          url: "https://1-tech-masters.com/supportOffice/public/api/login",
          data: {
            phone: this.phone,
            password: this.password,
          },
        })
          .then((response) => {
            alert("أهلاً بعودتك " + response.data.user.name);
            window.localStorage.setItem("token", response.data.token);
            if (response.data.role == "admin") {
              router.push("/control_panel");
            } else {
              router.back();
            }
          })
          .catch((error) => {
            console.log(error);
            alert("رقم الهاتف خاطئ او كلمة المرور خاطئة");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: 300;
  color: #d9be5e;
  transition: 0.5s ease-in-out;

  &.router-link-exact-active {
    text-decoration: underline;
    color: #d9be5e;
  }
}
a:hover {
  color: #c5ad58;
}
.container-1 {
  height: 100dvh;
  width: 100%;
  color: white;
  background-image: url(../assets/Photos/use_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.glass {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(217, 190, 94, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
}
.card {
  width: 40%;
  height: 70%;
}
.card-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.form_inp {
  width: 80%;
  margin: 20px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(217, 190, 94, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: none;
  text-align: end;
  padding: 20px;
  color: rgb(60, 60, 60);
}
input:focus {
  outline: #d9be5e 2px solid;
}

.show_hide {
  right: 80px;
  top: 295px;
  z-index: 2;
  cursor: pointer;
  position: absolute;
}

.login {
  height: 40px;
  width: fit-content;
  padding: 20px;
  font-size: 24px;
  color: white;
  background-color: #d9be5e;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}
.login:hover {
  background-color: #c5ad58;
}

@media screen and (max-width: 500px) {
  .container-1 {
    background-image: url(../assets/Photos/use_1_1.jpg);
  }
  .card {
    width: 80%;
    height: fit-content;
  }
  .show_hide {
    right: 60px;
    top: 282px;
  }
  .logo {
    width: 80px;
    height: fit-content;
  }
}
</style>
