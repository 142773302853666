<template>
  <div class="services-view">
    <div
      class="container w-100 d-flex justify-content-start align-items center"
      style="margin-top: 100px"
    >
      <div class="d-block w-100">
        <div class="d-flex justify-content-start align-items-center">
          <h1>{{ service_name }}</h1>
          <h1 v-if="service_name == 'السلة'">
            ({{ $store.state.elements_in_basket }})
          </h1>
        </div>
        <div class="row">
          <div class="col-12 d-flex">
            <router-link to="/"><h3>الرئيسية</h3></router-link>
            <span style="margin: 0 20px 0 20px"><h3>></h3></span>
            <h3>{{ service_name }}</h3>
            <span v-if="service_name2 != null" style="display: flex">
              <span style="margin: 0 20px 0 20px"><h3>></h3></span>
              <h3>{{ service_name2 }}</h3>
            </span>
          </div>
        </div>
        <!-- Saudi electronics -->
        <div
          class="row"
          v-if="
            service_name == 'الخدمات الإلكترونية السعودية' &&
            service_name2 == null
          "
        >
          <div class="col-md-3 col-6 my-3">
            <router-link to="/electronic-certificates" class="myservice">
              <div class="d-flex justify-content-center align-items-center">
                <div class="ser_type">
                  <Icon
                    icon="clarity:certificate-solid-badged"
                    color="white"
                    width="30"
                    height="30"
                  />
                </div>
              </div>
              <div class="ser_name">الوثائق والشهادات الإلكترونية</div>
            </router-link>
          </div>
          <div class="col-md-3 col-6 my-3">
            <router-link to="/gov-sup" class="myservice">
              <div class="d-flex justify-content-center align-items-center">
                <div class="ser_type">
                  <Icon
                    icon="ri:government-fill"
                    color="white"
                    width="30"
                    height="30"
                  />
                </div>
              </div>
              <div class="ser_name">الدعوم الحكومية الغير مستردة</div>
            </router-link>
          </div>
          <div class="col-md-3 col-6 my-3">
            <router-link to="/personal-financing" class="myservice">
              <div class="d-flex justify-content-center align-items-center">
                <div class="ser_type">
                  <Icon
                    icon="streamline:money-atm-card-1-credit-pay-payment-debit-card-finance-plastic-money"
                    color="white"
                    width="30"
                    height="30"
                  />
                </div>
              </div>
              <div class="ser_name">التمويل الشخصي</div>
            </router-link>
          </div>
          <div class="col-md-3 col-6 my-3">
            <router-link to="/projects-financing" class="myservice">
              <div class="d-flex justify-content-center align-items-center">
                <div class="ser_type">
                  <Icon icon="ps:store" color="white" width="30" height="30" />
                </div>
              </div>
              <div class="ser_name">تمويل المشاريع</div>
            </router-link>
          </div>
        </div>
        <!-- Support Academy -->
        <div
          class="row"
          v-if="service_name == 'أكاديمية دعم' && service_name2 == null"
        >
          <div class="col-md-3 col-6 my-3">
            <router-link to="/electronic-books" class="myservice">
              <div class="d-flex justify-content-center align-items-center">
                <div class="ser_type">
                  <Icon
                    icon="game-icons:white-book"
                    color="white"
                    width="30"
                    height="30"
                  />
                </div>
              </div>
              <div class="ser_name">الكتب الإلكترونية</div>
            </router-link>
          </div>
          <div class="col-md-3 col-6 my-3">
            <router-link to="/courses" class="myservice">
              <div class="d-flex justify-content-center align-items-center">
                <div class="ser_type">
                  <Icon
                    icon="clarity:certificate-solid-badged"
                    color="white"
                    width="30"
                    height="30"
                  />
                </div>
              </div>
              <div class="ser_name">الدورات التدريبية</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="service_name != 'السلة'">
      <div class="row">
        <div
          class="col-md-3 col-6 my-3"
          v-for="(element, index) in services"
          :key="services[index].id"
          :style="{
            display:
              index == 0
                ? 'none'
                : Object.keys(element).length == 0
                ? 'none'
                : 'block',
          }"
        >
          <ServiceCard
            :id="element.id"
            :img="element.image"
            :discount="element.discount != NAN ? element.discount : 0"
            :name="element.name"
            :price="element.price"
            :price_before="
              element.price_before != NAN ? element.price_before : 0
            "
          ></ServiceCard>
        </div>
        <div class="col-12 mt-5 mb-5" v-if="Object.keys(services).length == 0">
          <h1>عذرا لكن لا يوجد خدمات في هذا القسم بعد</h1>
        </div>
      </div>
    </div>
    <div class="container" v-if="service_name == 'السلة'">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";
import { Icon } from "@iconify/vue";
export default {
  name: "services-view",
  data() {
    return {
      page_type: 0,
      orders: {},
    };
  },
  props: {
    service_name: String,
    services: Object,
    service_name2: String,
  },
  components: {
    ServiceCard,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.buy-service {
  text-decoration: none;
  border: none;
  color: white;
  background-color: #d9be5e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}
.buy-service:hover {
  color: white;
  background-color: #ab964a;
}
a {
  font-weight: 300;
  text-decoration: none;
  color: rgba($color: #d9be5e, $alpha: 1);
  transition: 0.5s ease-in-out;

  &.router-link-exact-active {
    text-decoration: underline;
    color: #d9be5e;
  }
}
a:hover {
  color: #bea54c;
}

.ser_type {
  background-color: #d9be5e;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}

.myservice {
  color: #000000;
  transition: 0.5s ease-in-out;
}

.myservice:hover {
  color: #bea54c;
  .ser_type {
    background-color: #bea54c;
  }
}
</style>
