<template>
  <div class="add-service">
    <ControlPanelVue>
      <form>
        <div class="d-block w-100">
          <div class="container mt-5">
            <div class="row">
              <div class="col-6">
                <input
                  type="text"
                  name="name"
                  class="my-input"
                  placeholder="اسم الخدمة"
                  v-model="name"
                />
              </div>
              <div class="col-6">
                <input
                  type="number"
                  name="name"
                  class="my-input"
                  min="0"
                  placeholder="سعر الخدمة"
                  v-model="price"
                />
              </div>
              <div class="col-12 mt-3">
                <div class="dropdown w-100">
                  <button
                    class="btn dropdown-toggle w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ section_name }}
                  </button>
                  <ul
                    class="dropdown-menu w-100"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li
                      v-for="item in sections"
                      :key="item.id"
                      @click="
                        section_id = item.id;
                        section_name = item.name;
                      "
                    >
                      <div class="name">
                        {{ item.name }}
                      </div>
                      <div class="dropdown-divider"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <textarea
                  name="description"
                  placeholder="اكتب وصف الخدمة"
                  v-model="description"
                  rows="5"
                  class="my-input mt-5"
                ></textarea>
              </div>
              <div class="col-12">
                <div
                  class="d-flex justify-content-start align-items-center w-100 mt-3"
                >
                  اختر صورة الخدمة
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    id="image"
                    class="mx-3"
                    @change="changefile"
                  />
                </div>
              </div>
              <div class="col-12">
                <div
                  class="d-flex justify-content-start align-items-center w-100 mt-3"
                >
                  اتود اضافة حسم؟
                  <input
                    type="checkbox"
                    class="mx-3 flipswitch"
                    @click="flag == 0 ? (flag = 1) : (flag = 0)"
                  />
                </div>
              </div>
              <div class="col-6 mt-3" v-if="flag == 1">
                <input
                  type="text"
                  name="name"
                  class="my-input"
                  placeholder="قيمة الحسم"
                  v-model="discount"
                />
              </div>
              <div class="col-6 mt-3" v-if="flag == 1">
                <input
                  type="number"
                  name="name"
                  class="my-input"
                  min="0"
                  placeholder="السعر بعد الحسم"
                  v-model="offer_price"
                />
              </div>
              <div class="col-12">
                <button
                  type="button"
                  class="btn w-100 mt-3"
                  @click="addservice()"
                >
                  إضافة خدمة
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ControlPanelVue>
  </div>
</template>

<script>
import ControlPanelVue from "@/components/ControlPanel.vue";
import router from "@/router";
import axios from "axios";
export default {
  name: "add-service",
  data() {
    return {
      name: "",
      description: "",
      price: "",
      section_id: 0,
      img: "",
      section_name: "اسم القسم",
      sections: {
        0: {
          id: 2,
          name: "اشتراكات التطبيقات",
        },
        1: {
          id: 3,
          name: "خدمات المعلمين والطلاب",
        },
        2: {
          id: 4,
          name: "خدمات المتاجر والمواقع",
        },
        3: {
          id: 5,
          name: "خدمات السوشال ميديا",
        },
        4: {
          id: 6,
          name: "خدمات التصميم والمونتاج",
          created_at: "2023-09-22T17:41:22.000000Z",
        },
        5: {
          id: 7,
          name: "الكتب الإلكترونتية",
          created_at: "2023-09-22T17:41:59.000000Z",
        },
        6: {
          id: 8,
          name: "الدورات التدريبية",
          created_at: "2023-09-22T17:42:36.000000Z",
        },
        7: {
          id: 9,
          name: "الوثائق والشهادات الإلكترونية",
          created_at: "2023-09-22T17:50:15.000000Z",
        },
        8: {
          id: 10,
          name: "الدعوم الحكومية الغير مستردة",
          created_at: "2023-09-22T17:50:35.000000Z",
        },
        9: {
          id: 11,
          name: "التمويل الشخصي",
          created_at: "2023-09-22T17:51:10.000000Z",
        },
        10: {
          id: 12,
          name: "تمويل المشاريع",
          created_at: "2023-09-22T17:51:33.000000Z",
        },
      },
      flag: 0,
      discount: "",
      offer_price: "",
      service_id: 0,
    };
  },
  components: {
    ControlPanelVue,
  },
  methods: {
    changefile() {
      const input = document.getElementById("image");
      const file = input.files;
      this.img = file;
    },
    addservice() {
      if (this.name == "") {
        alert("يجب عليك تعبئة اسم الخدمة");
        return;
      } else if (this.price == "") {
        alert("يجب عليك ادخال سعر الخدمة");
        return;
      } else if (this.section_id == 0) {
        alert("يجب عليك اختيار القسم");
        return;
      } else if (this.description == "") {
        alert("يجب عليك تعبئة وصف الخدمة");
        return;
      } else if (this.img == "") {
        alert("يجب عليك اختيار صورة الخدمة");
        return;
      } else if (this.flag == 1) {
        if (this.discount == "") {
          alert("يحب عليك ادخال قيمة الحسم");
          return;
        } else if (this.offer_price == "") {
          alert("يجب عليك ادخال السعر بعد الحسم");
          return;
        }
      }

      const formData = new FormData();
      const imgFile = document.querySelector("#image");

      formData.append("image", imgFile.files[0]);
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("price", this.price);
      formData.append("section_id", this.section_id);

      axios
        .post(
          "https://1-tech-masters.com/supportOffice/public/api/store-service",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          alert("تم إضافة خدمة " + response.data.name + " بنجاح");
          this.service_id = response.data.id;
          if (this.flag == 1) {
            axios({
              method: "post",
              url:
                "https://1-tech-masters.com/supportOffice/public/api/store-offer/" +
                this.service_id,
              data: {
                discount: this.discount,
                offer_price: this.offer_price,
              },
            }).then((response2) => {
              alert("تم اضافة حسم للخدمة " + response2.data.name);
            });
          }
          router.push("/services");
        })
        .catch((error) => {
          console.error(error); // Handle any errors
        });
    },
  },
};
</script>

<style scoped>
.my-input {
  width: 100%;
  border-radius: 5px;
  border: none;
  font-size: 20px;
  padding-right: 10px;
}
.dropdown-menu {
  overflow-y: scroll;
  height: 200px;
}

.name {
  padding: 10px;
}
.name:hover {
  background-color: rgb(100, 100, 100);
}
.btn {
  background-color: #d9be5e;
  color: white;
}
.btn:hover {
  background-color: #d9be5e;
  color: white;
}

input[type="file"]::file-selector-button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: #d9be5e;
  color: white;
  transition: 0.5s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  background-color: #bba24a;
}

.flipswitch {
  position: relative;
  background: white;
  width: 120px;
  height: 40px;
  -webkit-appearance: initial;
  border-radius: 3px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  font-size: 14px;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid #ddd;
}

.flipswitch:after {
  position: absolute;
  top: 5%;
  display: block;
  line-height: 32px;
  width: 45%;
  height: 90%;
  background: #fff;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.3s ease-in 0s;
  color: black;
  border: #888 1px solid;
  border-radius: 3px;
}

.flipswitch:after {
  left: 2%;
  content: "لا";
}

.flipswitch:checked:after {
  left: 53%;
  content: "نعم";
  background: #d9be5e;
  color: #fff;
}
</style>
