<template>
  <div class="orders-view">
    <ControlPanelVue>
      <div class="d-block w-100 me-md-3 me-2">
        <div class="row bg-light mt-5">
          <div class="col-md-2 col-6 m-md-3 m-0 mt-2 mb-2">
            <button
              type="button"
              class="state-btn"
              :style="{
                color: page_type == 0 ? '#ffffff' : 'black',
                backgroundColor: page_type == 0 ? '#d9be5e' : '#ffffff',
              }"
              @click="changedata(0)"
            >
              فعال
            </button>
          </div>
          <div class="col-md-2 col-6 m-md-3 m-0 mt-2 mb-2">
            <button
              type="button"
              class="state-btn"
              :style="{
                color: page_type == 1 ? '#ffffff' : 'black',
                backgroundColor: page_type == 1 ? '#d9be5e' : '#ffffff',
              }"
              @click="changedata(1)"
            >
              منتهي
            </button>
          </div>
        </div>
        <div
          class="container w-100 mt-5 d-flex justify-content-center align-items-center"
        >
          <div class="table-responsive w-100">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">الرقم</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">الكمية</th>
                  <th scope="col">السعر</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">التاريخ</th>
                  <th scope="col" v-if="page_type == 0">تأكيد</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in display" :key="item.id">
                  <td scope="col">{{ item.id }}</td>
                  <td scope="col">{{ item.name }}</td>
                  <td scope="col">{{ item.quantity }}</td>
                  <td scope="col">{{ item.price }}</td>
                  <td scope="col">
                    {{ item.status == 0 ? "فعال" : "منتهي" }}
                  </td>
                  <td scope="col">{{ item.created_at }}</td>
                  <td scope="col" v-if="page_type == 0">
                    <button
                      type="button"
                      class="active-btn"
                      @click="changetodone(item.id)"
                    >
                      <Icon
                        icon="material-symbols:done"
                        height="24"
                        width="24"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ControlPanelVue>
  </div>
</template>

<script>
import ControlPanelVue from "@/components/ControlPanel.vue";
import router from "@/router";
import { Icon } from "@iconify/vue";
import axios from "axios";

export default {
  name: "orders-view",
  data() {
    return {
      page_type: 0,
      orders: {},
      display: {},
    };
  },
  components: {
    ControlPanelVue,
    Icon,
  },
  methods: {
    changedata(state) {
      this.page_type = state;
      this.display = {};
      for (let i = 0; i < Object.keys(this.orders).length; i++) {
        if (this.orders[i].status == state) {
          this.display[i] = this.orders[i];
        }
      }
    },
    getorders() {
      if (window.localStorage.getItem("token") === null) {
        router.push("/login");
      } else {
        const token = window.localStorage.getItem("token");
        axios({
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
          url: "https://1-tech-masters.com/supportOffice/public/api/cp/orders?all=1",
        })
          .then((response) => {
            console.log(response);
            this.orders = response.data;
            this.changedata(0);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    changetodone(id) {
      if (window.localStorage.getItem("token") === null) {
        router.push("/login");
      } else {
        const token = window.localStorage.getItem("token");
        axios({
          method: "post",
          headers: { Authorization: `Bearer ${token}` },
          url:
            "https://1-tech-masters.com/supportOffice/public/api/cp/done/" + id,
        })
          .then((response) => {
            if (response.data == "done") {
              alert("تمت العملية بنجاح");
              this.getorders();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getorders();
  },
};
</script>

<style scoped>
.state-btn {
  width: 100%;
  height: 50px;
  border: 1px solid #d9be5e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.active-btn {
  border-radius: 5px;
  border: none;
  background-color: #d9be5e;
  color: white;
}

@media screen and (max-width: 500px) {
  .state-btn {
    height: 30px;
    font-size: 17px;
  }
}
</style>
