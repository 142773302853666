<template>
  <div class="slider-component">
    <!-- services comp -->
    <div class="container my-1">
      <div class="row">
        <div
          class="col-6 d-flex justify-content-start heading-3"
          style="color: #d9be5e"
        >
          {{ name }}
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="showall">
            <router-link
              :to="url_ser"
              class="d-flex justify-content-center align-items-center heading-3"
              style="color: #282828; text-decoration: none"
              >عرض الكل</router-link
            >
            <div class="underline"></div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div class="slider" id="slider" ref="slider">
          <swiper
            :modules="modules"
            :slides-per-view="
              screen_width <= 500
                ? Object.keys(services).length >= 2
                  ? '2'
                  : '1'
                : Object.keys(services).length >= 4
                ? '4'
                : Object.keys(services).length
            "
            :space-between="35"
            data-swiper-autoplay="10000"
            autoplay
            navigation
            :pagination="{ clickable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="(element, index) in services"
              :key="services[index].id"
            >
              <ServiceCard
                class="card-width"
                :id="element.id"
                :img="element.image"
                :discount="element.discount != NAN ? element.discount : 0"
                :name="element.name"
                :price="element.price"
                :price_before="
                  element.price_before == Number ? element.price_before : 0
                "
              ></ServiceCard>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ServiceCard from "./ServiceCard.vue";

export default {
  name: "slider-component",
  data() {
    return {
      currentindex: 1,
      Name: this.name,
      new_id: 0,
      screen_width: screen.width,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ServiceCard,
  },
  props: {
    name: String,
    services: Object,
    url_ser: String,
  },
  setup() {
    var x;
    const onSwiper = (swiper) => {
      x = swiper;
      swiper = x;
    };
    const onSlideChange = () => {
      x;
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y, Autoplay],
    };
  },
  mounted() {
    this.new_id = parseInt(Math.random() * 100) + 100;
  },
};
</script>

<style scoped lang="scss">
.card-width {
  width: 255px;
}
/* for card row */
.heading-3 {
  font-size: 35px;
}

.slider {
  margin-top: 20px;
  width: 100%;
  height: 450px;
  text-align: left;
  overflow-x: scroll;
  display: flex;
  box-sizing: border-box;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scroll-margin-block-start: 0;
}
.slider::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 770px) {
  .heading-3 {
    font-size: 25px;
  }
}
@media screen and (max-width: 500px) {
  .card-width {
    width: 170px;
  }
  .heading-3 {
    font-size: 20px;
  }
  .slider {
    height: 300px;
  }
}

.underline {
  background-color: #d9be5e;
  height: 3px;
  width: 50%;
  position: relative;
  margin-bottom: 10px;
  transition: 0.5s ease-in-out;
}
.showall:hover {
  .underline {
    width: 100%;
  }
}

/* end */
</style>
