<template>
  <div class="home">
    <div class="container-1" id="start">
      <div style="width: 100%">
        <div
          class="w-100 d-flex justify-content-md-end justify-content-center align-items-center"
        >
          <div class="heading">
            <div class="d-flex justify-content-center for-margin">دعم</div>
            <div class="d-flex justify-content-center">للخدمات العامة</div>
          </div>
        </div>
      </div>
      <div class="arrow_first heading">
        <div
          class="d-flex justify-content-md-end justify-content-center align-items-center"
        >
          <a href="#who"><Icon icon="mdi:arrow" style="color: white" /> </a>
        </div>
      </div>
    </div>
    <div class="container-2" id="who">
      <div style="width: 100%" class="for-margin2">
        <div
          class="w-100 d-flex justify-content-md-end justify-content-center align-items-center"
        >
          <div>
            <div class="d-flex justify-content-center heading">من نحن</div>
            <div class="d-flex justify-content-center heading2">Who We Are</div>
          </div>
        </div>
      </div>
      <div style="width: 100%">
        <div
          class="w-100 d-flex justify-content-md-start justify-content-center align-items-center"
        >
          <div>
            <div
              class="d-flex justify-content-center paragraph text-sm-end text-center"
            >
              من أوائل مكاتب خدمات دعم التأمين المرخصة في المملكة العربية
              السعودية لممارسة مختلف أنواع خدمات التعقيب وفقاً لمبدأ الدعم من
              أوائل مكاتب خدمات دعم التأمين المرخصة في المملكة العربية السعودية
              لممارسة مختلف أنواع خدمات التعقيب وفقاً لمبدأ الدعم
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%">
        <div
          class="w-100 d-flex justify-content-md-end justify-content-center align-items-center"
        >
          <div>
            <div
              class="d-flex justify-content-center paragraph2 text-sm-end text-center"
            >
              *حرصاً من دعم على تقديم أفضل الخدمات التأمينية لعملائها، توفر ما
              يزيد عن 20 نوعا من أنواع الدعم الرقمي بما فيها رفع مطالبات التأمين
              الطبي والمركبات والحريق والحوادث البحرية وحوادث الطيران وتأمينات
              المسؤوليات وغيرها من أنواع التأمين الأخرى بالإضافة إلى فتح
              الحسابات الالكترونية وجميع انواع الخدمات الرقمية
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center arrow" style="width: 100%">
        <div class="d-flex justify-content-center">
          <a href="#services"
            ><Icon
              icon="solar:arrow-right-broken"
              style="color: rgb(40, 40, 40); margin-left: 150px"
            />
          </a>
        </div>
        <div class="d-flex justify-content-center">
          <a href="#start"
            ><Icon
              icon="solar:arrow-left-broken"
              style="color: rgb(40, 40, 40)"
            />
          </a>
        </div>
      </div>
    </div>
    <hr />
    <div class="container-3" id="services">
      <div style="width: 100%" class="for-margin2">
        <div class="d-flex justify-content-center heading">خدماتنا</div>
        <SliderComponent
          name="الخدمات الإلكترونية-السعودية"
          :services="saudi_services"
          :url_ser="'/electronic-saudi-services'"
        ></SliderComponent>
        <SliderComponent
          name="اشتراكات التطبيقات"
          :services="app_subscriptions"
          :url_ser="'/app-purches'"
        ></SliderComponent>
        <SliderComponent
          name="خدمات المعلمين والطلاب"
          :services="teacher_and_student_services"
          :url_ser="'/students-teachers'"
        ></SliderComponent>
        <SliderComponent
          name="خدمات المتاجر والمواقع"
          :services="websites"
          :url_ser="'/websites-services'"
        ></SliderComponent>
        <SliderComponent
          name="خدمات سوشال ميديا "
          :services="social_media"
          :url_ser="'/social-media-services'"
        ></SliderComponent>
        <SliderComponent
          name="خدمات التصميم والمونتاج "
          :services="photoshop"
          :url_ser="'/design-services'"
        ></SliderComponent>
        <SliderComponent
          name="الكتب الإلكترونية"
          :services="books"
          :url_ser="'/electronic-books'"
        ></SliderComponent>
        <SliderComponent
          name="الدورات التدريبية"
          :services="courses"
          :url_ser="'/courses'"
        ></SliderComponent>
      </div>
      <div class="d-flex justify-content-center arrow" style="width: 100%">
        <div class="d-flex justify-content-center">
          <a href="#contact"
            ><Icon
              icon="solar:arrow-right-broken"
              style="color: rgb(40, 40, 40); margin-left: 150px"
            />
          </a>
        </div>
        <div class="d-flex justify-content-center">
          <a href="#who"
            ><Icon
              icon="solar:arrow-left-broken"
              style="color: rgb(40, 40, 40)"
            />
          </a>
        </div>
      </div>
    </div>
    <hr />
    <div class="container-4 my-5" id="contact">
      <div style="width: 100%" class="for-margin2">
        <div class="d-flex justify-content-center heading w-100">
          تواصل معنا
        </div>
        <div class="d-flex justify-content-center heading2 shadow1 w-100">
          Connect with us
        </div>
        <div class="container heading2 my-5">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="contact_border">Facebook</div>
            </div>
            <div class="col-md-6 col-12">
              <div class="contact_border">Instagram</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="contact_border">Whatsapp</div>
            </div>
            <div class="col-md-6 col-12">
              <div class="contact_border">Twitter</div>
            </div>
          </div>
          <div class="d-flex justify-content-center arrow" style="width: 100%">
            <div class="d-flex justify-content-center">
              <a href="#footer"
                ><Icon
                  icon="solar:arrow-right-broken"
                  style="color: rgb(40, 40, 40); margin-left: 150px"
                />
              </a>
            </div>
            <div class="d-flex justify-content-center">
              <a href="#services"
                ><Icon
                  icon="solar:arrow-left-broken"
                  style="color: rgb(40, 40, 40)"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue";
import SliderComponent from "@/components/SliderComponent.vue";
import axios from "axios";
export default {
  name: "HomeView",
  data() {
    return {
      saudi_services: {},
      app_subscriptions: {},
      teacher_and_student_services: {},
      websites: {},
      social_media: {},
      photoshop: {},
      books: {},
      courses: {},
    };
  },
  components: { Icon, SliderComponent },
  methods: {
    getdata() {
      axios
        .get("https://1-tech-masters.com/supportOffice/public/api/home")
        .then((response) => {
          var arr1 = {};
          var counter = 0;
          var counter2 = 8;
          var i;
          while (counter2 < 12) {
            for (
              i = 0;
              i < Object.keys(response.data[counter2].service).length;
              i++
            ) {
              if (response.data[counter2].service[i].offer != null) {
                const price1 =
                  response.data[counter2].service[i].offer.offer_price;
                const discount1 =
                  response.data[counter2].service[i].offer.discount;
                const price_before1 = response.data[counter2].service[i].price;
                arr1[counter] = response.data[counter2].service[i];
                Object.assign(
                  arr1[counter],
                  { discount: discount1 },
                  { price_before: price_before1 },
                  { price: price1 }
                );
              } else {
                arr1[counter] = response.data[counter2].service[i];
              }
              counter++;
            }
            counter2++;
          }
          this.saudi_services = arr1;
          counter2 = 1;
          while (counter2 < 8) {
            arr1 = {};
            for (
              i = 0;
              i < Object.keys(response.data[counter2].service).length;
              i++
            ) {
              if (response.data[counter2].service[i].offer != null) {
                const price1 =
                  response.data[counter2].service[i].offer.offer_price;
                const discount1 =
                  response.data[counter2].service[i].offer.discount;
                const price_before1 = response.data[counter2].service[i].price;
                arr1[counter] = response.data[counter2].service[i];
                console.log(price1, discount1, price_before1);
                console.log(arr1[counter]);
                Object.assign(
                  arr1[counter],
                  { discount: discount1 },
                  { price_before: price_before1 },
                  { price: price1 }
                );
              } else {
                arr1[counter] = response.data[counter2].service[i];
              }
              counter++;
            }
            switch (counter2) {
              case 1: {
                this.app_subscriptions = arr1;
                break;
              }
              case 2: {
                this.teacher_and_student_services = arr1;
                break;
              }
              case 3: {
                this.websites = arr1;
                break;
              }
              case 4: {
                this.social_media = arr1;
                break;
              }
              case 5: {
                this.photoshop = arr1;
                break;
              }
              case 6: {
                this.books = arr1;
                break;
              }
              case 7: {
                this.courses = arr1;
                break;
              }
            }
            counter2++;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getdata();
  },
};
</script>

<style>
.container-1 {
  height: 100dvh;
  width: 100%;
  color: white;
  background-image: url(../assets/Photos/use_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-2 {
  width: 100%;
  height: 110dvh;
  background-image: url(../assets/Photos/use_4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.container-3 {
  width: 100%;
  height: max-content;
}
.container-4 {
  width: 100%;
  height: max-content;
  background-image: url(../assets/Photos/use_2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact_border {
  border: 8px solid #d9be5e;
  margin: 40px;
  font-size: 40px;
}

.shadow1 {
  text-shadow: 0px 0px 20px rgba(217, 190, 94, 1),
    2px 2px 20px rgba(217, 190, 94, 1);
  -webkit-text-stroke: 1px #d9be5e; /* width and color */
}

.for_circle {
  color: #d9be5e;
  font-size: 200px;
  position: absolute;
  left: 325px;
  bottom: -285px;
}

.heading {
  font-size: 100px;
  margin-left: 50px;
}

.arrow_first {
  margin-top: 9dvh;
  margin-left: 230px;
}

.heading2 {
  z-index: 2;
  font-size: 40px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.paragraph {
  font-size: 24px;
  width: 40dvw;
  margin-right: 30px;
}

.paragraph2 {
  font-size: 24px;
  width: 40dvw;
  margin-left: 10px;
}

.arrow {
  font-size: 70px;
  padding-top: 100px;
}

.for-margin {
  margin-top: 18dvh;
}

@media screen and (max-height: 920px) and (max-width: 500px) {
  .for-margin {
    margin-top: 250%;
  }
  .for-margin2 {
    margin-top: 100px;
  }
}

@media screen and (max-height: 800px) and (max-width: 450px) {
  .for-margin {
    margin-top: 180%;
  }
}

@media screen and (max-width: 500px) {
  .container-1 {
    background-image: url(../assets/Photos/use_1_1.jpg);
  }
  .heading {
    font-size: 50px;
  }
  .contact_border {
    font-size: 30px;
  }
}

@media screen and (max-width: 730px) {
  .heading {
    margin: 0px;
  }

  .arrow_first {
    margin: 0px;
    margin-top: 3dvh;
  }

  .paragraph {
    font-size: 15px;
    margin: 0;
  }

  .paragraph2 {
    font-size: 15px;
    margin: 0;
  }

  .arrow {
    font-size: 30px;
    padding-top: 0px;
  }
}
</style>
