<template>
  <div class="basket-view">
    <ServicesView service_name="السلة" :services="Basket">
      <div class="d-block w-100 me-md-3 me-2">
        <div class="row bg-light mt-5">
          <div class="col-md-2 col-6 m-md-3 m-0 mt-2 mb-2">
            <button
              type="button"
              class="state-btn"
              :style="{
                color: page_type == 0 ? '#ffffff' : 'black',
                backgroundColor: page_type == 0 ? '#d9be5e' : '#ffffff',
              }"
              @click="changedata(0)"
            >
              فعال
            </button>
          </div>
          <div class="col-md-2 col-6 m-md-3 m-0 mt-2 mb-2">
            <button
              type="button"
              class="state-btn"
              :style="{
                color: page_type == 1 ? '#ffffff' : 'black',
                backgroundColor: page_type == 1 ? '#d9be5e' : '#ffffff',
              }"
              @click="changedata(1)"
            >
              منتهي
            </button>
          </div>
        </div>
        <div
          class="container w-100 mt-5 d-flex justify-content-center align-items-center"
        >
          <div class="table-responsive w-100">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">الرقم</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">الكمية</th>
                  <th scope="col">السعر</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">التاريخ</th>
                  <th scope="col" v-if="page_type == 0">إلغاء</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in display" :key="item.id">
                  <td scope="col">{{ item.id }}</td>
                  <td scope="col">{{ item.name }}</td>
                  <td scope="col">{{ item.quantity }}</td>
                  <td scope="col">{{ item.price }}</td>
                  <td scope="col">
                    {{ item.status == 0 ? "فعال" : "منتهي" }}
                  </td>
                  <td scope="col">{{ item.created_at }}</td>
                  <td scope="col" v-if="page_type == 0">
                    <button
                      type="button"
                      class="active-btn"
                      @click="showhide(item.id)"
                    >
                      <Icon icon="iconoir:cancel" height="24" width="24" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ServicesView>
    <div class="overlay" id="popup1" style="visibility: hidden; opacity: 0">
      <div class="popup">
        <button type="button" class="close" @click="showhide(0)">
          <Icon
            icon="fluent-emoji-high-contrast:cross-mark"
            height="20"
            width="20"
          />
        </button>
        <div class="content desc p-5">
          <div class="d-block">
            <h2>هل أنت متأكد انك تريد إلغاء الطلب</h2>
            <div class="d-flex justify-content-center align-items-center">
              <button
                type="button"
                class="active-btn mx-1"
                style="width: 80px"
                @click="cancelorder()"
              >
                نعم
              </button>
              <button
                type="button"
                class="active-btn mx-1"
                style="
                  width: 80px;
                  background-color: white;
                  color: black;
                  border: 1px solid #d9be5e;
                "
                @click="showhide(0)"
              >
                لا
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServicesView from "@/components/ServicesView.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { Icon } from "@iconify/vue";
export default {
  name: "basket-view",
  data() {
    return {
      page_type: 0,
      Basket: {},
      display: {},
      id: 0,
      counter: 0,
    };
  },
  components: {
    ServicesView,
    Icon,
  },
  methods: {
    getuserorders() {
      if (window.localStorage.getItem("token") === null) {
        router.push("/login");
      } else {
        const token = window.localStorage.getItem("token");
        axios({
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
          url: "https://1-tech-masters.com/supportOffice/public/api/cp/orders?all=1",
        })
          .then((response) => {
            this.Basket = response.data;
            this.changedata(0);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    changedata(state) {
      this.page_type = state;
      if (state == 0) {
        store.state.elements_in_basket = 0;
      }
      this.display = {};
      for (let i = 0; i < Object.keys(this.Basket).length; i++) {
        if (this.Basket[i].status == state) {
          this.display[i] = this.Basket[i];
          if (state == 0) {
            store.state.elements_in_basket++;
          }
        }
      }
    },
    cancelorder() {
      if (window.localStorage.getItem("token") === null) {
        router.push("/login");
      } else {
        const token = window.localStorage.getItem("token");
        axios({
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
          url:
            "https://1-tech-masters.com/supportOffice/public/api/cp/delete-order/" +
            this.id,
        })
          .then((response) => {
            this.showhide(0);
            this.getuserorders();
            if (response.data == "deleted") {
              alert("تم إلغاء الطلب بنجاح");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    showhide(id) {
      if (id != 0) {
        this.id = id;
      }
      const element2 = document.getElementById("popup1");
      if (element2.style.visibility == "hidden") {
        const element2 = document.getElementById("popup1");
        element2.style.visibility = "visible";
        element2.style.opacity = 1;
      } else {
        element2.style.visibility = "hidden";
        element2.style.opacity = 0;
      }
    },
  },
  mounted() {
    this.getuserorders();
  },
};
</script>

<style scoped>
.state-btn {
  width: 100%;
  height: 50px;
  border: 1px solid #d9be5e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.active-btn {
  border-radius: 5px;
  border: none;
  background-color: #d9be5e;
  color: white;
}

@media screen and (max-width: 500px) {
  .state-btn {
    height: 30px;
    font-size: 17px;
  }
}
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  transition: opacity 500ms;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: max-content;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.popup .close:hover {
  color: #e57c23;
}

@keyframes opchange {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.desc {
  margin: 0 0 10px 0;
  font-size: 30px;
}

@media screen and (max-width: 650px) {
  .desc {
    font-size: 20px;
  }
}
</style>
