import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WebsitesServicesVue from "@/views/WebsitesServices.vue";
import DiscountsViewVue from "@/views/DiscountsView.vue";
import DesigningViewVue from "@/views/DesigningView.vue";
import SocialMediaVue from "@/views/SocialMedia.vue";
import StudentsTeachers from "@/views/StudentsTeachers.vue";
import AppPurchaseVue from "@/views/AppPurchase.vue";
import SaudiElectroicsVue from "@/views/SaudiElectronic/SaudiElectroics.vue";
import ElectronicCertificateVue from "@/views/SaudiElectronic/ElectronicCertificate.vue";
import GovernmentSupportVue from "@/views/SaudiElectronic/GovernmentSupport.vue";
import PersonalFinancingVue from "@/views/SaudiElectronic/PersonalFinancing.vue";
import ProjecrsFinancingVue from "@/views/SaudiElectronic/ProjectsFinancing.vue";
import SupportAcademyVue from "@/views/SupportAcademy/SupportAcademy.vue";
import OnlineBooksVue from "@/views/SupportAcademy/OnlineBooks.vue";
import TrainingCoursesVue from "@/views/SupportAcademy/TrainingCourses.vue";
import ShowServiceVue from "@/views/ShowService.vue";
import BasketViewVue from "@/views/Basket/BasketView.vue";
import LoginViewVue from "@/views/LoginView.vue";
import RegisterViewVue from "@/views/RegisterView.vue";
import BuyPageVue from "@/views/BuyPage.vue";
import MainViewVue from "@/views/Control Banel/MainView.vue";
import OrdersViewVue from "@/views/Control Banel/Orders/OrdersView.vue";
import ServicescpViewVue from "@/views/Control Banel/Services/ServicescpView.vue";
import AddServiceVue from "@/views/Control Banel/Services/AddService.vue";
import EditServiceVue from "@/views/Control Banel/Services/EditService.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/websites-services",
    name: "Website Services",
    component: WebsitesServicesVue,
  },
  {
    path: "/discounts",
    name: "Discounts Services",
    component: DiscountsViewVue,
  },
  {
    path: "/design-services",
    name: "Designing Services",
    component: DesigningViewVue,
  },
  {
    path: "/social-media-services",
    name: "Social Media Services",
    component: SocialMediaVue,
  },
  {
    path: "/students-teachers",
    name: "Students Teachers Services",
    component: StudentsTeachers,
  },
  {
    path: "/app-purches",
    name: "App Purchase Services",
    component: AppPurchaseVue,
  },
  {
    path: "/electronic-saudi-services",
    name: "Saudi Electronic Services",
    component: SaudiElectroicsVue,
  },
  {
    path: "/electronic-certificates",
    name: "Electronic Certificates Services",
    component: ElectronicCertificateVue,
  },
  {
    path: "/gov-sup",
    name: "Government Support Services",
    component: GovernmentSupportVue,
  },
  {
    path: "/personal-financing",
    name: "Personal Financing Services",
    component: PersonalFinancingVue,
  },
  {
    path: "/projects-financing",
    name: "Projects Financing Services",
    component: ProjecrsFinancingVue,
  },
  {
    path: "/academy-services",
    name: "Support Academy Services",
    component: SupportAcademyVue,
  },
  {
    path: "/electronic-books",
    name: "Online Books Services",
    component: OnlineBooksVue,
  },
  {
    path: "/courses",
    name: "Training Courses Services",
    component: TrainingCoursesVue,
  },
  {
    path: "/service-info",
    name: "Show Service",
    component: ShowServiceVue,
  },
  {
    path: "/basket",
    name: "Show Basket",
    component: BasketViewVue,
  },
  {
    path: "/login",
    name: "Login View",
    component: LoginViewVue,
  },
  {
    path: "/register",
    name: "Register View",
    component: RegisterViewVue,
  },
  {
    path: "/purchase",
    name: "Buy Page",
    component: BuyPageVue,
  },
  {
    path: "/control_panel",
    name: "Control Panel",
    component: MainViewVue,
  },
  {
    path: "/orders",
    name: "Orders View",
    component: OrdersViewVue,
  },
  {
    path: "/services",
    name: "Services View",
    component: ServicescpViewVue,
  },
  {
    path: "/add_service",
    name: "Add Service View",
    component: AddServiceVue,
  },
  {
    path: "/edit_service",
    name: "Edit Service View",
    component: EditServiceVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
