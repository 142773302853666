<template>
  <div class="show-service">
    <ServiceInformation :service_id="id"></ServiceInformation>
  </div>
</template>

<script>
import ServiceInformation from "@/components/ServiceInformation.vue";
import store from "@/store";

export default {
  name: "show-service",
  data() {
    return { id: store.state.service_id };
  },
  components: {
    ServiceInformation,
  },
  methods: {},
};
</script>
