<template>
  <div class="service-card">
    <div class="card mx-1">
      <div class="card-img-top">
        <img
          :src="
            'https://1-tech-masters.com/supportOffice/public/storage/' + img
          "
          class="slider-img"
        />
        <div v-if="discount != 0">
          <div v-if="discount != 0" class="b-value">{{ discount }}</div>
          <div v-if="discount != 0" class="b-back">
            <Icon icon="ic:baseline-discount" style="color: crimson" />
          </div>
        </div>
      </div>
      <div
        class="card-title heading-3 d-flex justify-content-center align-items-center"
        style="color: #d9be5e"
      >
        {{ name }}
      </div>
      <div class="container" style="padding-left: 45px">
        <div class="card-text text-center">
          <span class="price">السعر:{{ price }}</span>
        </div>
        <div class="card-text text-center price">
          السعر قبل الحسم:<span
            v-if="price_before > 0"
            style="color: rgb(177, 177, 177); text-decoration: line-through"
            >{{ price_before }}</span
          >
          <span v-if="price_before === 0" style="color: rgb(177, 177, 177)"
            >لا يوجد</span
          >
        </div>
        <div class="card-text text-center" v-if="!checkpage()">
          <span class="price">عدد مرات الشراء:{{ count }}</span>
        </div>
      </div>
      <div class="card-text d-flex justify-content-center" style="margin: 0">
        <router-link
          v-if="checkpage()"
          :to="'/service-info?service_id=' + id"
          class="buy-service"
          @click="$store.state.id = this.id"
          >شراء</router-link
        >
        <button
          v-if="!checkpage()"
          class="buy-service"
          @click="remove_from_basket()"
        >
          إزالة
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import store from "@/store";

export default {
  name: "service-card",
  data() {
    return {
      route: "",
      index: 0,
      count: 0,
    };
  },
  components: {
    Icon,
  },
  props: {
    id: Number,
    img: String,
    discount: Number,
    name: String,
    price: String,
    price_before: String,
  },
  methods: {
    checkpage() {
      if (this.route.includes("basket")) {
        return false;
      }
      return true;
    },
    getcount() {
      var basket = store.state.basket;
      console.log(basket);
      if (Object.keys(basket).length > 1) {
        for (var i = 1; i < Object.keys(basket).length; i++) {
          if (basket[i].id == this.id) {
            this.count = basket[i].count;
            this.index = i;
            break;
          }
        }
      }
    },
    remove_from_basket() {
      if (Object.keys(store.state.basket).length > 1) {
        store.state.amount -= this.price * this.count;
        for (
          var i = this.index;
          i < Object.keys(store.state.basket).length - 1;
          i++
        ) {
          store.state.basket[i] = store.state.basket[i + 1];
          store.state.serBasket[i] = store.state.serBasket[i + 1];
        }
      }
      store.state.basket[store.state.elements_in_basket] = {};
      store.state.serBasket[store.state.elements_in_basket] = {};
      store.state.elements_in_basket--;
    },
  },
  mounted() {
    this.route = window.location.href;
    this.getcount();
  },
};
</script>

<style scoped>
.card-text {
  width: 100%;
  margin-right: 20px;
}

.price {
  font-size: 25px;
}

.heading-3 {
  font-size: 35px;
}

.buy-service {
  text-decoration: none;
  border: none;
  color: white;
  background-color: #d9be5e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 130px;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}
.buy-service:hover {
  background-color: #ab964a;
}

@media screen and (max-width: 500px) {
  .heading-3 {
    font-size: 25px;
  }
  .card-text .buy-service {
    height: 40px;
    width: 80px;
  }
  .card {
    flex: 0 0 200px;
  }
  .slider {
    flex-wrap: nowrap;
  }
  .price {
    font-size: 13px;
  }
}

.slider > .card {
  flex: 0 0 270px;
  background-color: white;
  color: #282828;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}

.b-value {
  position: absolute;
  left: 35px;
  top: 40px;
  height: 70px;
  font-size: 30px;
  z-index: 4;
  color: white;
}

.b-back {
  position: absolute;
  left: 10px;
  top: 0px;
  font-size: 80px;
  height: 70px;
  z-index: 3;
}

@media screen and (max-width: 500px) {
  .slider > .card {
    flex: 0 0 200px;
  }

  .b-back {
    font-size: 50px;
  }
  .b-value {
    top: 25px;
    font-size: 20px;
    left: 25px;
  }
}

.slider-img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

/* end */
</style>
