<template>
  <div class="websites-services">
    <ServicesView
      service_name="خدمات المتاجر والمواقع"
      :services="websites"
    ></ServicesView>
  </div>
</template>

<script>
import ServicesView from "@/components/ServicesView.vue";
import axios from "axios";
export default {
  name: "websites-services",
  components: {
    ServicesView,
  },
  data() {
    return {
      websites: {},
    };
  },
  methods: {
    getservices() {
      axios
        .get(
          "https://1-tech-masters.com/supportOffice/public/api/get-services-for-section/4"
        )
        .then((response) => {
          for (var i = 0; i < Object.keys(response.data.service).length; i++) {
            if (response.data.service[i].offer != null) {
              const price1 = response.data.service[i].offer.offer_price;
              const discount1 = response.data.service[i].offer.discount;
              const price_before1 = response.data.service[i].price;
              this.websites[i + 1] = response.data.service[i];
              Object.assign(
                this.websites[i + 1],
                { discount: discount1 },
                { price_before: price_before1 },
                { price: price1 }
              );
            } else {
              this.websites[i + 1] = response.data.service[i];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getservices();
  },
};
</script>
