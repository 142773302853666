<template>
  <div class="register-view">
    <div class="container-1" id="start">
      <div class="card glass">
        <div class="card-title mt-5">
          <img src="@/assets/Photos/use_6_copy.png" class="logo" alt="" />
        </div>
        <div class="card-title m-3">
          <h1>مركز الدعم للخدمات العامة</h1>
        </div>
        <div class="card-text mt-3">
          <div class="row w-100">
            <div class="col-12">
              <input
                type="text"
                class="form_inp"
                name="fname"
                v-model="fname"
                placeholder="ادخل اسمك"
              />
            </div>
            <div class="col-12">
              <input
                type="text"
                class="form_inp"
                name="lname"
                v-model="lname"
                placeholder="ادخل الكنية"
              />
            </div>
            <div class="col-12">
              <input
                type="email"
                class="form_inp"
                name="email"
                v-model="email"
                placeholder="ادخل الايميل"
              />
            </div>
            <div class="col-12">
              <input
                type="text"
                class="form_inp"
                name="phone"
                v-model="phone"
                placeholder="ادخل رقم الهاتف"
              />
            </div>
            <div class="col-12">
              <input
                id="pass"
                type="password"
                name="password"
                class="form_inp"
                v-model="password"
                placeholder="ادخل كلمة المرور"
              />
              <Icon
                :icon="myicon"
                :color="mycolor"
                class="show_hide"
                @click="showpassword(1)"
              />
            </div>
            <div class="col-12">
              <input
                id="conpass"
                type="password"
                name="conpass"
                class="form_inp"
                v-model="conpassword"
                placeholder="اعد ادخال كلمة المرور"
              />
              <Icon
                :icon="myicon2"
                :color="mycolor2"
                class="show_hide2"
                @click="showpassword(2)"
              />
            </div>
            <div
              class="col-12 d-flex justify-content-center align-items-center"
            >
              <button class="login" type="button" @click="register_account()">
                إنشاء حساب
              </button>
            </div>
            <div
              class="mt-3 mb-3 col-12 d-flex justify-content-center align-items-center"
            >
              <router-link to="/login">لديك حساب؟</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Icon } from "@iconify/vue";
import router from "@/router";
export default {
  name: "register-view",
  data() {
    return {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      password: "",
      conpassword: "",
      myicon: "gridicons:visible",
      mycolor: "#3c3c3c",
      myicon2: "gridicons:visible",
      mycolor2: "#3c3c3c",
    };
  },
  components: {
    Icon,
  },
  methods: {
    showpassword(num) {
      const ele = document.getElementById("pass");
      const ele2 = document.getElementById("conpass");
      if (num == 1) {
        if (ele.type == "password") {
          ele.type = "text";
          this.myicon = "gridicons:not-visible";
          this.mycolor = "#ffffff";
        } else {
          ele.type = "password";
          this.myicon = "gridicons:visible";
          this.mycolor = "#3c3c3c";
        }
      } else {
        if (ele2.type == "password") {
          ele2.type = "text";
          this.myicon2 = "gridicons:not-visible";
          this.mycolor2 = "#ffffff";
        } else {
          ele2.type = "password";
          this.myicon2 = "gridicons:visible";
          this.mycolor2 = "#3c3c3c";
        }
      }
    },
    register_account() {
      if (this.fname == "") {
        window.alert("First name is empty");
        return;
      } else if (this.lname == "") {
        window.alert("Last name is empty");
        return;
      } else if (this.email == "") {
        window.alert("Email is empty");
        return;
      } else if (this.phone == "") {
        window.alert("Phone is empty");
        return;
      } else if (this.password == "") {
        window.alert("password is empty");
        return;
      } else if (this.conpassword == "") {
        window.alert("confirm passwod is empty");
        return;
      } else if (this.password != this.conpassword) {
        window.alert("password don't match");
        return;
      } else {
        axios({
          method: "post",
          url: "https://1-tech-masters.com/supportOffice/public/api/register",
          data: {
            name: this.fname + " " + this.lname,
            email: this.email,
            phone: this.phone,
            password: this.password,
          },
        })
          .then((response) => {
            alert(
              "شكراً لتسجيلك على موقعنا " +
                response.data.name +
                " عليك تسجيل الدخول الآن"
            );
            router.push("/login");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: 300;
  color: #d9be5e;
  transition: 0.5s ease-in-out;

  &.router-link-exact-active {
    text-decoration: underline;
    color: #d9be5e;
  }
}
a:hover {
  color: #c5ad58;
}
.container-1 {
  padding: 100px;
  height: 100%;
  width: 100%;
  color: white;
  background-image: url(../assets/Photos/use_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.glass {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(217, 190, 94, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
}
.card {
  width: 40%;
  height: fit-content;
}
.card-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}
.form_inp {
  width: 80%;
  margin: 20px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(217, 190, 94, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: none;
  text-align: end;
  padding: 20px;
  color: rgb(60, 60, 60);
}
input:focus {
  outline: #d9be5e 2px solid;
}

.show_hide {
  right: 80px;
  top: 535px;
  z-index: 2;
  cursor: pointer;
  position: absolute;
}

.show_hide2 {
  right: 80px;
  top: 615px;
  z-index: 2;
  cursor: pointer;
  position: absolute;
}

.login {
  height: 40px;
  width: fit-content;
  padding: 20px;
  font-size: 24px;
  color: white;
  background-color: #d9be5e;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
}
.login:hover {
  background-color: #c5ad58;
}

@media screen and (max-width: 500px) {
  .container-1 {
    padding: 0;
    background-image: url(../assets/Photos/use_1_1.jpg);
  }
  .card {
    width: 80%;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .show_hide {
    right: 60px;
    top: 525px;
  }
  .show_hide2 {
    right: 60px;
    top: 605px;
  }
  .logo {
    width: 80px;
    height: fit-content;
  }
}
</style>
