<template>
  <div class="main-view">
    <ControlPanelVue>
      <div
        class="row d-flex justify-content-md-start justify-content-center align-items-start mt-5 w-100"
      >
        <div class="col-md-4 col-12">
          <router-link to="/orders" class="card-link">
            <div class="card">
              <div
                class="card-title d-flex justify-content-center align-items-center"
              >
                <div class="d-block">
                  <div class="d-flex justify-content-center align-items-center">
                    <Icon
                      icon="icon-park-solid:order"
                      width="40"
                      height="40"
                      class="mx-2"
                    />
                    <span class="d-md-block d-none">الطلبات</span>
                  </div>
                  <div class="number-line">عدد الطلبات: {{ orders }}</div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-12">
          <router-link to="/services">
            <div class="card">
              <div
                class="card-title d-flex justify-content-center align-items-center"
              >
                <div class="d-block">
                  <div class="d-flex justify-content-center align-items-center">
                    <Icon
                      icon="mingcute:service-fill"
                      width="40"
                      height="40"
                      class="mx-2"
                    />
                    <span class="d-md-block d-none">الخدمات</span>
                  </div>
                  <div class="number-line">عدد الخدمات: {{ services }}</div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-12">
          <div class="card">
            <div
              class="card-title d-flex justify-content-center align-items-center"
            >
              <div class="d-block">
                <div class="d-flex justify-content-center align-items-center">
                  <Icon
                    icon="mingcute:service-fill"
                    width="40"
                    height="40"
                    class="mx-2"
                  />
                  <span class="d-md-block d-none">المستخدمين</span>
                </div>
                <div class="number-line">عدد المستخدمين: {{ users }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ControlPanelVue>
  </div>
</template>

<script>
import ControlPanelVue from "@/components/ControlPanel.vue";
import router from "@/router";
import { Icon } from "@iconify/vue";
import axios from "axios";

export default {
  name: "main-view",
  data() {
    return {
      orders: 0,
      services: 0,
      users: 0,
    };
  },
  components: {
    ControlPanelVue,
    Icon,
  },
  methods: {
    gethome() {
      if (window.localStorage.getItem("token") === null) {
        router.push("/login");
      } else {
        const token = window.localStorage.getItem("token");
        axios({
          method: "get",
          headers: { Authorization: `Bearer ${token}` },
          url: "https://1-tech-masters.com/supportOffice/public/api/cp/home",
        })
          .then((response) => {
            this.services = response.data[0];
            this.orders = response.data[1];
            this.users = response.data[2];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.gethome();
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: 300;
  text-decoration: none;
  color: rgba($color: rgb(40, 40, 40), $alpha: 1);
  transition: 0.5s ease-in-out;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
a:hover {
  color: #d9be5e;
}
.card {
  padding: 10px;
  font-size: 40px;
  font-weight: 300;
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out;
}
.card:hover {
  color: #d9be5e;
}
.number-line {
  font-size: 20px;
  color: rgb(100, 100, 100);
}
@media screen and (max-width: 500px) {
  .card {
    width: 100%;
  }
}
</style>
