<template>
  <div class="navbar-component">
    <div class="mynav">
      <div>
        <nav
          class="navbar navbar-expand-md navbar-dark fixed-top"
          style="background-color: rgba(40, 40, 40, 0.8)"
        >
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
              <ul class="navbar-nav me-auto mb-2 mb-md-0 w-100">
                <li class="nav-item mx-2">
                  <router-link to="/websites-services"
                    >خدمات المتاجر والمواقع</router-link
                  >
                </li>
                <li class="nav-item mx-2">
                  <router-link to="/discounts">تخفيضات</router-link>
                </li>
                <li class="nav-item mx-2">
                  <router-link to="/design-services"
                    >خدمات التصميم والمونتاج</router-link
                  >
                </li>
                <li class="nav-item mx-2">
                  <router-link to="/social-media-services"
                    >خدمات السوشال ميديا</router-link
                  >
                </li>
                <li class="nav-item mx-2">
                  <router-link to="/students-teachers"
                    >خدمات المعلمين والطلاب</router-link
                  >
                </li>
                <li class="nav-item mx-2">
                  <router-link to="/app-purches"
                    >اشتراكات التطبيقات</router-link
                  >
                </li>
                <li class="nav-item dropdown mx-2">
                  <router-link
                    to="/electronic-saudi-services"
                    class="nav-link dropdown-toggle"
                    style="margin: 0; padding: 0; transition: 0.5s ease-in-out"
                    id="navbarDropdown"
                    role="button"
                    aria-expanded="false"
                    >الخدمات الإلكترونية السعودية</router-link
                  >
                  <ul
                    class="dropdown-menu animate slideIn"
                    style="
                      background-color: rgba(40, 40, 40, 0.8);
                      direction: rtl;
                      text-align: start;
                    "
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <router-link to="/electronic-certificates"
                        >الوثائق والشهادات الإلكترونية</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/gov-sup"
                        >الدعوم الحكومية الغير مستردة</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/personal-financing"
                        >التمويل الشخصي</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/projects-financing"
                        >تمويل المشاريع</router-link
                      >
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown mx-2">
                  <router-link
                    to="/academy-services"
                    class="nav-link dropdown-toggle"
                    style="margin: 0; padding: 0; transition: 0.5s ease-in-out"
                    id="navbarDropdown"
                    role="button"
                    aria-expanded="false"
                    >أكاديمية دعم</router-link
                  >
                  <ul
                    class="dropdown-menu animate slideIn"
                    style="
                      background-color: rgba(40, 40, 40, 0.8);
                      direction: rtl;
                      text-align: start;
                    "
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <router-link to="/electronic-books"
                        >الكتب الإلكترونية</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/courses">الدورات التدريبية</router-link>
                    </li>
                  </ul>
                </li>
                <li class="nav-item mx-2">
                  <div
                    class="card d-flex justify-content-center align-items-center"
                    style="background-color: unset; border: none; width: 100%"
                  >
                    <div class="card-img-top">
                      <router-link to="/basket">
                        <Icon
                          icon="simple-line-icons:basket"
                          width="25"
                          height="25"
                        />
                        <span class="count_basket">{{
                          $store.state.elements_in_basket
                        }}</span>
                      </router-link>
                    </div>
                  </div>
                </li>
                <li class="nav-item mx-2 d-md-none d-block">
                  <button @click="logout()" class="mybtn">
                    {{ $store.state.login }}
                  </button>
                </li>
              </ul>
            </div>
            <button
              @click="logout()"
              class="navbar-brand nav-item d-md-block d-none mybtn"
            >
              {{ $store.state.login }}
            </button>
            <router-link to="/" class="navbar-brand d-md-block d-none">
              <img src="@/assets/Photos/use_6_copy.png" class="logo" alt="" />
            </router-link>
          </div>
        </nav>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import { Icon } from "@iconify/vue";
export default {
  name: "navbar-component",
  components: {
    Icon,
  },
  methods: {
    logout() {
      if (store.state.login == "تسجيل الدخول") {
        router.push("/login");
      } else {
        window.localStorage.removeItem("token");
        if (store.state.save_url == "/") {
          location.reload();
        } else {
          router.push("/");
        }
      }
    },
  },
};
</script>

<style scoped>
.mybtn {
  padding: 0;
  margin: 0;
  background-color: unset;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s ease-in-out;
}
.mybtn:hover {
  color: white;
}
.count_basket {
  position: absolute;
  font-size: 15px;
  left: 0;
  bottom: 0;
}

.mynav {
  z-index: 30;
}

.line {
  background-color: white;
  height: 3px;
  width: 100%;
  position: fixed;
  z-index: 30;
  top: 55px;
  -webkit-box-shadow: 0px 0px 30px 0px gold;
  -moz-box-shadow: 0px 0px 30px 0px gold;
  box-shadow: 0px 0px 30px 0px gold;
}

.nav-item {
  font-size: 18px;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}

.dr-link {
  background-color: rgba(40, 40, 40, 0.8);
}

@media screen and (max-height: 920px) and (max-width: 500px) {
  .line {
    top: 55px;
  }
  .count_basket {
    left: 45%;
  }
}

@media (min-width: 200px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
</style>
