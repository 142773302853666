<template>
  <div class="control-panel">
    <div class="row" style="width: 100%">
      <div class="col-3 side-bar">
        <div class="d-flex justify-content-center align-items-start mt-5">
          <div class="d-block w-100">
            <div
              style="color: white"
              class="d-flex justify-content-center align-items-center"
            >
              <div class="d-block">
                <img
                  src="../assets/Photos/use_6_copy.png"
                  class="logo mb-md-1 mb-3"
                  alt=""
                />
                <h3 class="d-md-block d-none">مركز الدعم للخدمات العامة</h3>
              </div>
            </div>
            <div>
              <router-link
                to="/control_panel"
                class="control-route justify-content-md-start justify-content-center"
              >
                <Icon
                  icon="material-symbols:speed"
                  width="24"
                  height="24"
                  class="mx-2"
                />
                <span class="d-md-block d-none"> لوحة التحكم </span>
              </router-link>
            </div>
            <div>
              <router-link
                to="/orders"
                class="control-route justify-content-md-start justify-content-center"
              >
                <Icon
                  icon="icon-park-solid:order"
                  width="24"
                  height="24"
                  class="mx-md-2"
                />
                <span class="d-md-block d-none">الطلبات</span>
              </router-link>
            </div>
            <div>
              <router-link
                to="/services"
                class="control-route justify-content-md-start justify-content-center"
              >
                <Icon
                  icon="mingcute:service-fill"
                  width="24"
                  height="24"
                  class="mx-2"
                />
                <span class="d-md-block d-none">الخدمات</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-9 d-flex justify-content-md-start justify-content-center align-items-start"
      >
        <slot class="w-100"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  name: "control-panel",
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  background: rgb(100, 100, 100);
  height: 100dvh;
}
a {
  font-weight: 300;
  text-decoration: none;
  color: rgba($color: #ffffff, $alpha: 0.5);
  transition: 0.5s ease-in-out;
  font-size: 24px;
  display: flex;
  align-items: center;

  &.router-link-exact-active {
    text-decoration: underline;
    color: #d9be5e;
  }
}
a:hover {
  color: #ffffff;
}

.control-route {
  margin: 10px;
}
.logo {
  height: 40px;
  width: fit-content;
}
@media screen and (max-width: 500px) {
  .logo {
    height: 15px;
    width: fit-content;
  }
}
</style>
