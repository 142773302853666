<template>
  <div class="service-information">
    <div
      class="container w-100 d-flex justify-content-start align-items center"
      style="margin-top: 100px"
    >
      <div class="d-block w-100">
        <div class="d-flex justify-content-start align-items-center">
          <h1>{{ service.name }}</h1>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-12 d-flex">
            <router-link to="/"><h3>الرئيسية</h3></router-link>
            <span style="margin: 0 20px 0 20px"><h3>></h3></span>
            <h3>{{ service.name }}</h3>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="row bg-light info">
            <div class="col-md-5 col-12 my-md-0 my-3">
              <div class="card" style="border-radius: 10px">
                <div class="card-img-top">
                  <img
                    class="service_img"
                    :src="
                      'https://1-tech-masters.com/supportOffice/public/storage/' +
                      service.img
                    "
                    alt=""
                  />
                  <div v-if="service.discount != ''">
                    <div v-if="service.discount != ''" class="b-value">
                      {{ service.discount }}
                    </div>
                    <div v-if="service.discount != ''" class="b-back">
                      <Icon
                        icon="ic:baseline-discount"
                        style="color: crimson"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-12">
              <div class="d-flex justify-content-start align-items-center desc">
                <span style="color: #d9be5d; margin-left: 5px"
                  >اسم الخدمة:</span
                >
                {{ service.name }}
              </div>
              <div class="d-flex justify-content-start align-items-center desc">
                <div class="d-block" style="direction: rtl; text-align: start">
                  <div style="color: #d9be5d">وصف الخدمة:</div>
                  <div>{{ service.description }}</div>
                </div>
              </div>
              <div class="d-flex justify-content-start align-items-center desc">
                <span style="color: #d9be5d; margin-left: 5px"
                  >سعر الخدمة:</span
                >
                {{ service.price }}
              </div>
              <div
                v-if="service.discount != ''"
                class="d-flex justify-content-start align-items-center desc"
              >
                <span style="color: #d9be5d; margin-left: 5px"
                  >سعر الخدمة قبل الحسم:</span
                >
                <span
                  style="
                    text-decoration: line-through;
                    color: rgb(177, 177, 177);
                  "
                >
                  {{ service.price_before }}</span
                >
              </div>
              <div
                class="d-flex justify-content-center align-items-center desc"
              >
                <span style="color: #d9be5d; margin-left: 5px"
                  >عدد مرات الشراء:</span
                >
                <span>
                  <form class="form form-control">
                    <input
                      type="number"
                      class="form-input"
                      min="0"
                      v-model="buying_times"
                    /></form
                ></span>
              </div>
              <div
                class="d-flex justify-content-center align-items-center desc"
              >
                <button class="addto" @click="buying()">شراء</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" id="popup1" style="visibility: hidden; opacity: 0">
      <div class="popup">
        <button type="button" class="close" @click="show_hide()">
          <Icon
            icon="fluent-emoji-high-contrast:cross-mark"
            height="20"
            width="20"
          />
        </button>
        <div class="content desc p-5">
          عذراً ولكن عليك إضافة عدد مرات شراء اولاً
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import { Icon } from "@iconify/vue";
import axios from "axios";

export default {
  name: "service-information",
  data() {
    return {
      service: {
        id: 1,
        name: "",
        description: "",
        img: "",
        price: "",
        discount: "",
        price_before: 0,
      },
      buying_times: 0,
    };
  },
  components: {
    Icon,
  },
  props: {
    service_id: Number,
  },
  methods: {
    buying() {
      if (this.buying_times == 0) {
        const element2 = document.getElementById("popup1");
        element2.style.visibility = "visible";
        element2.style.opacity = 1;
      } else if (window.localStorage.getItem("token") === null) {
        store.state.save_url = window.location.href;
        router.push("/login");
      } else {
        const token = window.localStorage.getItem("token");
        axios({
          method: "post",
          headers: { Authorization: `Bearer ${token}` },
          url:
            "https://1-tech-masters.com/supportOffice/public/api/cp/add-order/" +
            store.state.service_id,
          data: { quantity: this.buying_times },
        })
          .then((response) => {
            alert("تم تسجيل طلبك بنجاح رقم العملية " + response.data.id);
            router.push("/basket");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    show_hide() {
      const element2 = document.getElementById("popup1");
      element2.style.visibility = "hidden";
      element2.style.opacity = 0;
    },
    getservices() {
      axios
        .get(
          "https://1-tech-masters.com/supportOffice/public/api/show-service/" +
            store.state.service_id
        )
        .then((response) => {
          this.service.id = response.data.id;
          this.service.name = response.data.name;
          this.service.img = response.data.image;
          this.service.description = response.data.description;
          if (response.data.offer != null) {
            this.service.price = response.data.offer.offer_price;
            this.service.price_before = response.data.price;
            this.service.discount = response.data.offer.discount;
          } else {
            this.service.price_before = 0;
            this.service.price = response.data.price;
            this.service.discount = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getservices();
  },
};
</script>

<style lang="scss" scoped>
a {
  font-weight: 300;
  text-decoration: none;
  color: rgba($color: #d9be5e, $alpha: 1);
  transition: 0.5s ease-in-out;

  &.router-link-exact-active {
    text-decoration: underline;
    color: #d9be5e;
  }
}
a:hover {
  color: #bea54c;
}

.service_img {
  width: 100%;
  height: fit-content;
  border-radius: 10px;
}
.desc {
  margin: 0 0 10px 0;
  font-size: 30px;
}
.info {
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.form-control {
  padding: 0;
  border-radius: 10px;
  border: none;
}

.form-input {
  background-color: rgb(177, 177, 177);
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
}

.form-input:focus {
  outline: none !important;
  border: #d9be5e solid 2px;
}

.addto {
  background-color: #d9be5e;
  border-radius: 10px;
  color: white;
  width: 100%;
  transition: 0.5s ease-in-out;
  border: none;
}

.addto:hover {
  background-color: #bea54c;
  color: white;
}

.b-value {
  position: absolute;
  left: 35px;
  top: 40px;
  height: 70px;
  font-size: 30px;
  z-index: 4;
  color: white;
  animation: opchange infinite 2s;
}

.b-back {
  position: absolute;
  left: 10px;
  top: 0px;
  font-size: 80px;
  height: 70px;
  z-index: 3;
}
// popup
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  transition: opacity 500ms;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: max-content;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  background-color: #f4f4f4;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.popup .close:hover {
  color: #e57c23;
}

@media screen and (max-width: 650px) {
  .info {
    width: 98%;
  }
  .desc {
    font-size: 20px;
  }
  .form-input {
    font-size: 10px;
  }
}

@keyframes opchange {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
