<template>
  <div class="servicescp-view">
    <ControlPanelVue>
      <div class="d-block w-100 me-md-3 me-2">
        <div class="row bg-light mt-5">
          <div class="col-md-4 col-12 m-md-3 m-0 mt-2 mb-2">
            <button
              type="button"
              class="state-btn"
              @click="$router.push('/add_service')"
            >
              إضافة خدمة
            </button>
          </div>
        </div>
        <div
          class="container w-100 mt-5 d-flex justify-content-center align-items-center"
        >
          <div class="table-responsive w-100">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">الرقم</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">السعر</th>
                  <th scope="col">القسم</th>
                  <th scope="col">تعديل</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in services" :key="item.id">
                  <td scope="col">{{ item.id }}</td>
                  <td scope="col">{{ item.name }}</td>
                  <td scope="col">{{ item.price }}</td>
                  <td scope="col">
                    {{
                      item.section_id == 2
                        ? "اشتراكات التطبيقات"
                        : item.section_id == 3
                        ? "خدمات المعلمين والطلاب"
                        : item.section_id == 4
                        ? "خدمات المتاجر والمواقع"
                        : item.section_id == 5
                        ? "خدمات السوشال ميديا"
                        : item.section_id == 6
                        ? "خدمات التصميم والمونتاج"
                        : item.section_id == 7
                        ? "الكتب الإلكترونتية"
                        : item.section_id == 8
                        ? "الدورات التدريبية"
                        : item.section_id == 9
                        ? "الوثائق والشهادات الإلكترونية"
                        : item.section_id == 10
                        ? "الدعوم الحكومية الغير مستردة"
                        : item.section_id == 11
                        ? "التمويل الشخصي"
                        : "تمويل المشاريع"
                    }}
                  </td>
                  <td scope="col">
                    <button
                      type="button"
                      class="active-btn"
                      @click="
                        $router.push('/edit_service?service_id=' + item.id)
                      "
                    >
                      <Icon
                        icon="material-symbols:edit"
                        height="24"
                        width="24"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ControlPanelVue>
  </div>
</template>

<script>
import ControlPanelVue from "@/components/ControlPanel.vue";
import { Icon } from "@iconify/vue";

export default {
  name: "servicescp-view",
  data() {
    return {
      page_type: "active",
      services: {
        0: {
          id: 12,
          name: "service1",
          price: "2000",
          section_id: 2,
        },
        1: {
          id: 2,
          name: "service2",
          price: "2000",
          section_id: 2,
        },
        2: {
          id: 3,
          name: "service3",
          price: "2000",
          section_id: 3,
        },
        3: {
          id: 4,
          name: "service4",
          price: "2000",
          section_id: 4,
        },
        4: {
          id: 5,
          name: "service5",
          price: "2000",
          section_id: 6,
        },
        5: {
          id: 6,
          name: "service6",
          price: "2000",
          section_id: 7,
        },
        6: {
          id: 7,
          name: "service7",
          price: "2000",
          section_id: 10,
        },
        7: {
          id: 8,
          name: "service8",
          price: "2000",
          section_id: 5,
        },
      },
    };
  },
  components: {
    ControlPanelVue,
    Icon,
  },
  methods: {
    changedata(state) {
      this.page_type = state;
      this.display = {};
      for (let i = 0; i < Object.keys(this.orders).length; i++) {
        if (this.orders[i].state == state) {
          this.display[i] = this.orders[i];
        }
      }
    },
  },
};
</script>

<style scoped>
.state-btn {
  width: 100%;
  height: 50px;
  border: 1px solid #d9be5e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background-color: #d9be5e;
  color: white;
}
.active-btn {
  border-radius: 5px;
  border: none;
  background-color: #d9be5e;
  color: white;
}

@media screen and (max-width: 500px) {
  .state-btn {
    height: 30px;
    font-size: 17px;
  }
}
</style>
